@tailwind base;
@tailwind components;
@tailwind utilities;


.border-rgb {
  position: relative;
  background-color: rgb(255, 255, 255) !important;

}

.border-rgb:before, .border-rgb:after{
  content: '';
  position: absolute;
  top: -2px;
  left: -2px;
  background: linear-gradient(45deg, #fb0094, #0000ff, #00ff00, #ffff00, #ff0000, #fb0094, #0000ff, #00ff00, #ffff00, #ff0000) ;
  background-size: 400%;
  width: calc(100% + 4px);
  height: calc(100% + 4px);
  animation: border-rgb-animate 25s linear infinite;
  border-radius: 0.5rem;
  z-index: 1;
}
.border-rgb:after {
  filter: blur(20px);
}



.border-rgb-circle-absolute {
  position: absolute;
  background-color: rgb(255, 255, 255) !important;

}

.border-rgb-circle-absolute:before, .border-rgb-circle-absolute:after{
  content: '';
  position: absolute;
  top: -2px;
  left: -2px;
  background: linear-gradient(45deg, #fb0094, #0000ff, #00ff00, #ffff00, #ff0000, #fb0094, #0000ff, #00ff00, #ffff00, #ff0000) ;
  background-size: 400%;
  width: calc(100% + 4px);
  height: calc(100% + 4px);
  animation: border-rgb-animate 25s linear infinite;
  z-index: 10;
  border-radius: 9999px;
}
.border-rgb-circle-absolute:after {
  filter: blur(20px);
}



.background-rgb,  .background-rgb:after{
  position: relative;
  background: linear-gradient(45deg, #fb0094, #0000ff, #00ff00, #ffff00, #ff0000, #fb0094, #0000ff, #00ff00, #ffff00, #ff0000) ;
  background-size: 400%;
  width: calc(100% + 4px);
  height: calc(100% + 4px);
  animation: border-rgb-animate 25s linear infinite;
  border-radius: 0.5rem;

}
 .background-rgb:before{
  position: absolute;
  background: linear-gradient(45deg, #fb0094, #0000ff, #00ff00, #ffff00, #ff0000, #fb0094, #0000ff, #00ff00, #ffff00, #ff0000) ;
  background-size: 400%;
  width: calc(100% + 4px);
  height: calc(100% + 4px);
  animation: border-rgb-animate 25s linear infinite;
  border-radius: 0.5rem;
  z-index: -1;

}
.background-rgb:after{
  filter: blur(20px);
}





.border-rgb-hover:hover {
  position: relative;
  background-color: rgb(255, 255, 255) !important;

}

.border-rgb-hover:hover:before, .border-rgb-hover:hover:after{
  content: '';
  position: absolute;
  top: -2px;
  left: -2px;
  background: linear-gradient(45deg, #fb0094, #0000ff, #00ff00, #ffff00, #ff0000, #fb0094, #0000ff, #00ff00, #ffff00, #ff0000) ;
  background-size: 400%;
  width: calc(100% + 4px);
  height: calc(100% + 4px);
  animation: border-rgb-animate 25s linear infinite;
  border-radius: 0.5rem;
  z-index: 1;
}
.border-rgb-hover:hover:after {
  filter: blur(20px);
}
















@keyframes border-rgb-animate {
  0%{
    background-position: 0 0;
  }
  50%{
    background-position: 400% 0 ;
  }
  100%{
    background-position: 0 0;
  }
}